<template>
    <div class="page">
        <div class="main-img">
            <van-image width="100%" height="100%" :src="goods.img" fit="contain" />
        </div>
        <div class="goods-info">
            <div class="title">{{ goods.name }}</div>
            <div class="price-wrap">
                <span class="price">{{ goods.integral }}</span>
                <span class="unit">积分</span>
                <span class="num">{{ goods.sales_num }}人兑换</span>
            </div>
        </div>
        <div class="goods-content">
            <div class="label">商品详情</div>
            <div class="content" v-html="goods.content"></div>
        </div>

        <div class="btn-wrap">
            <van-button type="primary" size="large" color="#FAB021" :disabled="btnDisable" @click="exchange">{{
                btnTxt
            }}</van-button>
        </div>
    </div>
</template>

<script>
import { Toast } from 'cube-ui'
export default {
    name: 'IntegralGoods',
    mounted () {
        let goods_id = this.$route.query.id
        this.getGoodsInfo(goods_id)
    },
    data () {
        return {
            goods: { img: '' },
            use_integral: 0,
            btnFlag: false
        }
    },
    computed: {
        btnTxt () {
            return this.use_integral >= parseInt(this.goods.integral) ? '立即兑换' : '积分不足'
        },
        btnDisable () {
            return (this.use_integral < parseInt(this.goods.integral)) || this.btnFlag
        }
    },

    methods: {
        getGoodsInfo (goods_id) {
            this.$http.getIntegralGoodsInfo({
                id: goods_id,
            }).then(res => {
                if (res.code == 200) {
                    this.goods = res.data.goods
                    this.use_integral = parseInt(res.data.use_integral)
                }
            }).catch(err => {
                this.$router.push({ path: '/integral-shop' })
            });
        },
        exchange () {
            if (this.btnDisable) return
            this.btnFlag = true
            this.$http.exchangeIntegralGoods({
                id: this.goods.id
            }).then(res => {
                if (res.code == 200) {
                    Toast.$create({
                        type: 'correct',
                        txt: "兑换成功",
                        time: 1500,
                        onTimeout: () => {
                            this.$router.push({ path: '/exchangelist' })
                        }
                    }).show()
                } else {
                    Toast.$create({
                        type: 'error',
                        txt: "兑换失败",
                        time: 1500,
                        onTimeout: () => {
                            location.reload();
                        }
                    }).show()
                }
            }).computed(() => {
                this.btnFlag = false
            })
            return false;
        }
    },
}
</script>

<style scoped lang="less">
.page {
    .main-img {
        height: 195px;
    }

    .goods-info {
        padding: 5px 17px 10px 17px;
        border-top: 1px solid #707070;

        .title {
            line-height: 18px;
            font-size: 18px;
            font-family: Source Han Sans CN, Source Han Sans CN;
            font-weight: 500;
            color: #707070;
        }

        .price-wrap {
            margin-top: 10px;
            display: flex;
            flex-direction: row;
            align-items: flex-end;

            .price {
                height: 20px;
                font-size: 20px;
                font-family: Source Han Sans CN, Source Han Sans CN;
                font-weight: bold;
                color: #E31F1F;
            }

            .unit {
                margin: 0 7px 0 4px;
                height: 10px;
                font-size: 10px;
                font-family: Source Han Sans CN, Source Han Sans CN;
                font-weight: 400;
                color: #E31F1F;
            }

            .num {
                height: 8px;
                font-size: 8px;
                font-family: Source Han Sans CN, Source Han Sans CN;
                font-weight: 400;
                color: #B3AFAF;
            }
        }
    }

    .goods-content {
        padding-bottom: 60px;

        .label {
            border-top: 1px solid #707070;
            border-bottom: 1px solid #707070;
            padding: 8px 20px 9px 20px;
            height: 15px;
            font-size: 15px;
            font-family: Source Han Sans CN, Source Han Sans CN;
            font-weight: bold;
            color: #000000;
        }

        .content {
            padding: 5px;
        }

    }

    .btn-wrap {
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
    }
}
</style>
